import React from 'react';
import ThankYou from '../../../page-components/ThankYou';

export default function ThankYouPage() {
  return (
    <ThankYou
      thankYouMessage="Thank you! Nhân viên của công ty sẽ liên lạc với bạn trong thời gian nhanh nhất."
      phoneText="(508) 847 - 6815"
      phoneNumber="5088476815"
    />
  );
}
